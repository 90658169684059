.hero-img {
  width: 100%;
  height: 60vh;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

.hero-img::before {
  content: "";
  background: url("https://images.unsplash.com/photo-1522252234503-e356532cafd5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8ZGV2ZWxvcGVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60");
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading h1 {
  font-size: 2.4rem;
}

.hero-img p {
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .heading h1 {
    font-size: 2rem;
  }
}
